.crud-modal {
	.ant-tabs-tab {
		padding-left: 0 !important;
	}
	.ant-modal-title {
		color: @primary-color;
	}
	.ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 24px 30px;
		border-top: unset;
		button {
			margin-left: 15px !important;
		}
	}
	.form-steps {
		display: flex;
		.ant-steps {
			width: 300px;
			border-right: 1px solid #c9c9c9;
			margin-right: 30px;
			box-sizing: unset;
		}
		.ant-steps-item {
			flex: unset;
		}
		.steps-content {
			width: calc(100% - 300px);
		}
		.ant-steps-item-content {
			min-height: 56px;
			line-height: 44px;
		}
		.ant-steps-item-active {
			box-shadow: inset -2px 0px 0px @primary-color;
		}
		.ant-steps-item-error {
			&.ant-steps-item {
				color: @ui-red;
				.ant-steps-icon-dot {
					background: @ui-red;
				}
				.ant-steps-item-title {
					color: @ui-red;
				}
			}
		}
	}
	input,
	.ant-select-selector,
	.ant-picker,
	.ant-input-affix-wrapper,
	.ant-upload button {
		border-radius: 8px !important;
	}
	.main-table .ant-input-affix-wrapper {
		border-radius: 0 !important;
	}
	.ant-input-group {
		.ant-input-group-addon {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		input {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
	.ant-modal-content {
		border-radius: 16px;
	}
	.ant-modal-header {
		border-radius: 16px 16px 0 0;
	}
	.ant-upload-disabled {
		display: none;
	}
	.modalErr {
		color: red;
		white-space: pre-wrap;
		margin: 5px 0px;
		max-height: 200px;
		overflow: hidden;
		overflow-y: auto;
		border: 1px dashed #ddd;
		padding: 15px 0px 15px 15px;
	}
}

@primary-color: #61a599;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;