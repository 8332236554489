.ant-notification-top {
	left: 50%;
	transform: translate(-50%, 0);
	right: unset !important;
	bottom: unset !important;
	top: 20px !important;
	margin-right: unset !important;
	.ant-notification-notice-message {
		padding: 3px 0;
		margin-bottom: 0;
	}
}

.modalPre {
	white-space: pre-wrap;
}

@primary-color: #61a599;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;