.sider {
	background: white !important;
	box-sizing: border-box;
	overflow: hidden;
	ul {
		border-right: unset;
	}
	.logo-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 64px;
		padding: 0 20px 0 24px;
		font-size: 16px;
		img {
			cursor: pointer;
		}
		span {
			margin-left: 8px;
		}
		svg {
			color: @primary-color;
		}
	}
	.menuItem {
		color: @ui-black !important;
		font-size: 16px;
		&:hover {
			background: @ui-blue-hover !important;
		}
		&:active {
			background: @ui-grey;
		}
		&:after {
			left: 0 !important;
			right: 0 !important;
		}
		svg {
			height: 100%;
			color: @primary-color;
		}
	}

	.ant-menu-submenu {
		.ant-menu {
			padding-top: 4px !important;
			background-color: #fff;
		}

		.ant-menu-item-selected {
			background: @ui-grey !important;
		}
	}
}

.sider.ant-layout-sider-collapsed {
	.logo-container {
		justify-content: center;
	}
	.collapse-container {
		margin-right: 0;
		justify-content: center;
	}
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
	padding: 0 calc(50% - 24px / 2);
}

@primary-color: #61a599;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;