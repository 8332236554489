.form-group-title {
	color: @primary-color;
	font-size: 16px;
	margin-bottom: 15px;
}


.form-group-delete {
	border: 1px solid transparent !important;
//    background-color: transparent;
   box-shadow: none !important;

	svg {
		color: @ui-red;
		display: flex;
		justify-content: flex-start;
		// background-color: transparent;
		// border: 1px  transparent;
		margin-top: -20px;
	}

}
.ant-row .ant-row-middle{
	display: flex;
	justify-content:flex-start;
	align-items:center;
	align-self:center;
	max-width: 220px !important;
}
.rating-button {
	.ant-radio-wrapper-checked {
		border: 1px solid @primary-color;
		border-radius: 8px;
	}
	.ant-radio-wrapper {
		padding: 5px;
	}
	.ant-radio-inner {
		border-color: @ui-black;
	}
}

@primary-color: #61a599;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;