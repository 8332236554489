@import 'utils/less/_vairables.less';

.regLayout {
	display: flex;
	justify-content: center;
	align-items: center;

	&__header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: @regLayout_header_height;
		background-color: @primary-color;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 40px 0 40px;
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		min-height: @regLayout_footer_height;
		width: 100vw;
		background-color: @primary-color;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: white;
	}

	&__main {
		position: absolute;
		top: @regLayout_header_height;
		height: @regLayout_main_height;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;

		&_formContainer {
			width: 100%;
			max-width: 550px;
			margin: 10px;
			padding: 25px 80px 25px 80px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 1px solid #c9c9c9;

			&_title {
				text-transform: uppercase;
				font-weight: 600;
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.02em;
				color: @primary-color;
				display: flex;
				flex-direction: column;
				align-items: center;
				box-sizing: border-box;

				#divider {
					width: 80px;
					height: 0;
					margin: 16px 0 10px 0;
					border-bottom: 1px solid @primary-color;
				}
			}

			&_footer {
				:nth-child(2) {
					margin-left: 8px;
					text-decoration: underline;
				}
			}

			&_form {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.ant-form-item-explain-error {
					margin-bottom: 5px;
				}

				&_input {
					background: #ffffff;
					border: none;
					border-bottom: 1px solid #c9c9c9;
					height: 48px;
					outline: none;
				}

				&_button,
				.ant-btn,
				.ant-btn-primary {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 12px;
					outline: none;
					border: none;
					height: 48px;
					width: 100%;
					background: @primary-color;
				}
			}
		}

		&_confirm {
			width: 31.1%;
			height: 37.1%;
			background: #ffffff;
			border: 1px solid #c9c9c9;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			padding: 0 50px;

			.title {
				font-weight: 600;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				color: @primary-color;
			}

			.divider {
				width: 80px;
				border-bottom: 1px solid @primary-color;
			}

			.content {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 0.04em;
				color: @ui-black;
			}

			.footer {
				text-decoration-line: underline;
			}
		}
	}
}
.regLayout__main_formContainer_form_input {
	height: 48px;
}
@media (max-width: 768px) {
	.regLayout {
		&__main {
			&_formContainer {
				padding: 25px 40px;
			}
		}
	}
}

@primary-color: #61a599;@ui-blue: #38456F;@ui-black: #111010;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;